import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import "./Styles.scss";

const words = ["Software", "Fullstack", "Frontend", "Backend", "Games"];
const fudge = 20;

const WordAnimator = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const wordRef = useRef(null);
  const underlineRef = useRef(null); // Ref for the underline element

  useLayoutEffect(() => {
    const updateWord = () => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    };

    const interval = setInterval(() => {
      // Start fade-out after 1 second (for the pause)
      setTimeout(() => {
        if (wordRef.current) {
          wordRef.current.classList.remove("fadeIn");
          wordRef.current.classList.add("fadeOut");
        }
      }, 1000); // Start fade-out after 1 second pause

      // Change word after fade-out (which takes 200 ms)
      setTimeout(updateWord, 2500); // Change word after fade-out completes
    }, 4500); // Total interval: 1s pause + 200ms fade-out + 1s before next word starts to fade in

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (wordRef.current) {
      setMaxWidth(wordRef.current.offsetWidth + fudge);
      wordRef.current.classList.remove("fadeOut");
      wordRef.current.classList.add("fadeIn");
    }
  }, [currentWordIndex]); // Update maxWidth and start fade-in when word changes

  return (
    <div className="wordanimator">
      <span>A </span>
      <span
        className="text-primary word-container"
        style={{ width: `${maxWidth}px` }}
      >
        <span ref={wordRef} className="word-animate fadeIn">
          {words[currentWordIndex]}
        </span>
        <span ref={underlineRef} className="word-underline"></span>
      </span>
      <span> Developer</span>
    </div>
  );
};

export default WordAnimator;
